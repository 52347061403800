import React from "react"
import LayoutXT from "../components/LayoutXT"

export default function Informationen() {
  return (
    <LayoutXT>
      <h1 className="rem-of-18 left">
        <a href="http://www.kanzlei-schweikert.de" className="red bold">
          Kanzlei Schweikert
        </a>
      </h1>
      <div className="rem-of-13 gray-a lheight18">
        <p>
          Rechtsanwalt
        </p>
        <p>
          <b>Rechtsanwalt Friedrich Schweikert</b>
        </p>
        <p>
          <b>Kontakt</b>
          <br />
          Lagerhausstr. 12, 83043 Bad Aibling
          <br />
          Postfach 1248 in 83036 Bad Aibling
          <br />
          Telefon: + 49 8061 9386895
          <br />
          Telefax: + 49 8061 9386894
          <br />
          E-Mail: kanzlei(at)kanzlei-schweikert.de
        </p>
        <p>
          Verantwortlich für die Seiten:
          <br />
          <a href="http://www.kanzlei-schweikert.de">www.kanzlei-schweikert.de</a>
          , www.ra-schweikert.de, www.anwalt-schweikert.de,{" "}
          <a href="http://www.alpinrecht.eu">http://www.alpinrecht.eu</a>,
          alpenrecht.de und alpin-recht.de.          
        </p>
        <p>
          <b>Rechtsform, Register</b>
          <br />
          Die Kanzlei Schweikert ist Firmierung des Einzel- Rechtsanwaltes
          Friedrich Schweikert. Der Rechtsanwaltstätigkeit wird als Freiberufler
          nachgegangen.          
        </p>
        <p>
          <b>Berufsbezeichnung und zuständige Kammern</b>
          <br />
          Rechtsanwalt Friedrich ist nach dem Recht der Bundesrepublik Deutschland
          zugelassen und Mitglieder der Rechtsanwaltskammer München, Im Tal 33,
          80331 München, info(at)rak-muenchen.de, http://www.rak-muenchen.de.
        </p>
        <p>
          <b>Steuernummer</b>
          <br />
          156/272/71198          
        </p>
        <p>
          <b>Umsatzsteueridentifikationsnummer (§ 27a UStG)</b>
          <br />
          UStIdNr. DE275670914
        </p>
        <p>
          <b>Berufshaftpflichtversicherung </b>
          <br />
          HDI Gerling, Dürrenhofstr. 4-6, 90402 Nürnberg
        </p>
        <p>
          Geltungsbereich der Berufshaftpflichtversicherung:
          <br />
          1.) Deutschland
          <br />
          2.) Europäisches Ausland
          <br />
          Versichert sind Haftpflichtansprüche aus Tätigkeiten
          <br />
          (1) im Zusammenhang mit der Beratung und Beschätigung mit europäischem
          Recht;
          <br />
          (2) des Rechtsanwaltes vor europäischen Gerichten
          <br />
          3.) Weltweit in Höhe der gesetzlich vorgeschriebenen
          Mindestversicherungssumee für Haftpflichtansprüche aus der
          Inanspruchnahme des Versicherungsnehmers vor außereuropäischen
          Gerichten.
          <br />
          4.) Der Versicherungsschutz bezieht sich nicht auf Haftpflichtansprüche
          aus Tätigkeiten über im Ausland eingerichtete oder unterhaltene
          Kanzleien oder Büros.
        </p>
        <p>
          <b>Berufsrechtliche Regelungen</b>
          <br />
          Es gelten die folgenden berufsrechtlichen Regelungen:
          <br />
          Bundesrechtsanwaltsordnung (BRAO),
          <br />
          Berufsordnung (BORA),
          <br />
          Fachanwaltsordnung (FAO)
          <br />
          Rechtsanwaltsvergütungsgesetz (RVG)
          <br />
          Berufsregeln der Rechtsanwälte der Europä-ischen Union (CCBE)
        </p>
        <p>
          Die berufsrechtlichen Regelungen können über die Homepage der
          Bundesrechtsanwaltskammer (www.brak.de) in der Rubrik "Berufsrecht" auf
          Deutsch und Englisch eingesehen und abgerufen werden.
        </p>
        <p>
        Die Wahrnehmung widerstreitender Interessen ist Rechtsanwälten aufgrund
        berufsrechtlicher Regelungen untersagt (§ 43a Abs. 4 BRAO). Vor Annahme
        eines Mandates wird deshalb immer geprüft, ob ein Interessenkonflikt
        vorliegt.
        </p>
        <p>
          <b>Außergerichtliche Streitschlichtung </b>
          <br />
          Bei Streitigkeiten zwischen Rechtsanwälten und ihren Auftraggebern
          besteht auf Antrag die Möglichkeit der außergerichtlichen
          Steitschlichtung bei der regionalen Rechtsanwaltskammer München(gemäß §
          73 Abs. 2 Nr. 3 i.V.m. § 73 Abs. 5 BRAO) oder bei der Schlichtungsstelle
          der Rechtsanwaltschaft (§ 191f BRAO) bei der Bundesrechtsanwaltskammer,
          im Internet zu finden über die Homepage der Bundesrechtsanwaltskammer
          (www.brak.de), E-Mail: schlichtungsstelle(at)brak.de.
        </p>
        <p>
          <b>AGBs</b>
          <br />
          <b>
            Die Haftung des Rechtsanwaltes ist auf 1.000.000,00 € begrenzt, sofern
            kein Vorsatz oder grobe Fahrlässigkeit vorliegt.
          </b>          
        </p>
        <p>
          Zur Webseite der{" "}
          <a href="http://www.kanzlei-schweikert.de">Kanzlei Schweikert</a>
        </p>
      </div>
    </LayoutXT>
  )
}
